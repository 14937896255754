import { axiosATSSInstance } from "../../../utils/axios_config"

const PermitServiceURL = {
    permit: 'permit',
    permitDelete: 'permit/delete',
    permitType: 'permit/type',
    permitVehicle : 'permit/vehicle',
    permitVehicles : 'permit/vehicles',
    permitSave: 'permit/save',
    permitSaveBulk: 'permit/save_bulk',
    requiredPermit : 'permit/required',
    requiredPermitSave : 'permit/required/save',
    requiredPermitSaveBulk : 'permit/required/save_bulk',
    bannedPermit : 'permit/banned',
    bannedPermitSave : 'permit/banned/save',
    bannedPermitSaveBulk : 'permit/banned/save_bulk',
    preferredPermit : 'permit/preferred',
    preferredPermitSave : 'permit/preferred/save',
    preferredPermitSaveBulk : 'permit/preferred/save_bulk',

}

export class PermitService {
    static getInstance() {
        return new PermitService();
    }

    async getPermitsByAssetType(data) {
        return axiosATSSInstance.post(PermitServiceURL.permit, data);
    }
    async getRequiredPermit(data) {
        return axiosATSSInstance.post(PermitServiceURL.requiredPermit, data);
    }
    async getBannedPermit(data) {
        return axiosATSSInstance.post(PermitServiceURL.bannedPermit, data);
    }
    async getPreferredPermit(data) {
        return axiosATSSInstance.post(PermitServiceURL.preferredPermit, data);
    }
    async getPermitType() {
        return axiosATSSInstance.post(PermitServiceURL.permitType);
    }

    async savePermit(data) {
        return axiosATSSInstance.put(PermitServiceURL.permitSave,data);
    }
    async saveRequiredPermit(data) {
        return axiosATSSInstance.put(PermitServiceURL.requiredPermitSave,data);
    }
    async saveBannedPermit(data) {
        return axiosATSSInstance.put(PermitServiceURL.bannedPermitSave,data);
    }
    async savePreferredPermit(data) {
        return axiosATSSInstance.put(PermitServiceURL.preferredPermitSave,data);
    }
    async saveBulkPermit(data) {
        return axiosATSSInstance.post(PermitServiceURL.permitSaveBulk,data);
    }
    async saveBulkRequiredPermit(data) {
        return axiosATSSInstance.post(PermitServiceURL.requiredPermitSaveBulk,data);
    }
    async saveBulkBannedPermit(data) {
        return axiosATSSInstance.post(PermitServiceURL.bannedPermitSaveBulk,data);
    }
    async saveBulkPreferredPermit(data) {
        return axiosATSSInstance.post(PermitServiceURL.preferredPermitSaveBulk,data);
    }
    async getPermitVehicle(vehicle) {
        return axiosATSSInstance.get(`${PermitServiceURL.permitVehicle}/${vehicle}`);
    }
    async getPermitVehicles(plantID) {
        return axiosATSSInstance.post(`${PermitServiceURL.permitVehicles}/${plantID}`);
    }
    
    async deletePermit(id) {
        return axiosATSSInstance.delete(`${PermitServiceURL.permitDelete}/${id}`);
    }
    errorHandler(error) {
        const config = error.config;
        const response = error.response;

        if (response && response.status === 400) {
            switch (config.url) {
                case PermitServiceURL.permit:
                    return {
                        title: 'Invalid Permit Request',
                        message: response.data || 'Please check your permit request parameters'
                    }
                case PermitServiceURL.permitValidation:
                    return {
                        title: 'Invalid Validation Request',
                        message: response.data || 'Please check your validation parameters'
                    }
                default:
                    return {
                        title: 'Invalid Request',
                        message: 'Please check the request parameters'
                    }
            }
        }
        else if (response && response.status === 404) {
            return {
                title: 'Permit Not Found',
                message: 'The requested permit could not be found'
            }
        }
        else if (response && response.status === 500) {
            return {
                title: 'Internal Server Error',
                message: 'An unexpected error occurred while processing your request'
            }
        }
        
        return {
            title: `Unknown Error ${response ? `(${response.status})` : ''}`,
            message: 'An unexpected error occurred'
        }
    }
}