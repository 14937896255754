import { combineReducers } from "@reduxjs/toolkit";
import dashboardReducer from './dashboard';
import manageRosterReducer from './manage_roster';
import permitReducer from "./permit";

const appReducer = combineReducers({
    dashboard: dashboardReducer,
    manageRoster: manageRosterReducer,
    permit : permitReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
