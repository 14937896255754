import { useState, useEffect, Fragment } from "react";
import { Menu, MenuItem, ListItemText, ListItemAvatar, Typography, Avatar, Box, CircularProgress, useTheme, Stack, IconButton } from "@mui/material";
import BackHandIcon from '@mui/icons-material/BackHand';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CancelIcon from '@mui/icons-material/Cancel';
import coneIcon from '../icons/cone_icon.png';
import warningLight from '../icons/warning_light.png';
import wheelBarrow from '../icons/wheelbarrow.png';
import admix from '../icons/admix.png';
import delay from '../icons/delay.png';
import site from '../icons/site.png';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import useNotification from '../containers/dashboard/hooks/use_notification';
import RefreshIcon from '@mui/icons-material/Refresh';
import ErrorIcon from '@mui/icons-material/Error';
import RatingModal from "./RatingModal";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ReceiptIcon from '@mui/icons-material/Receipt';

function Notifications({ open, handleClose, anchorEl }) {

    const theme = useTheme();
    const { notiData, loading, failed, hasNextPage, retry, fetcMorehNoti, sendRating } = useNotification();

    const [isMounted, setIsMounted] = useState(false);
    const [rateModal, setRateModal] = useState(false);
    const [rateData, setRateData] = useState(null);

    useEffect(() => {
        if (!isMounted) {
            retry();
            setIsMounted(true);
        }
    }, []);

    const parseDate = (date) => {
        const tempDate = new Date(date);
        tempDate.setHours(tempDate.getHours());// + 8);
        const month = tempDate.toLocaleString('default', { month: 'short' });
        const day = tempDate.toLocaleString('default', { day: '2-digit' });
        const year = tempDate.toLocaleString('default', { year: 'numeric' })
        const timeVal = tempDate.toLocaleString('default', { timeStyle: 'short', hour12: false });
        return day + " " + month + " " + year + " at " + timeVal;
    }

    const parseMessage = (message) => {
        return JSON.parse(message);
    }

    const avatarImg = (message) => {
        let iconColor = theme.palette.error.main;
        if (message.includes("Hold")) {
            return <BackHandIcon htmlColor={iconColor} />;
        } else if (message.includes("Reject")) {
            return <ThumbDownIcon htmlColor={iconColor} />;
        } else if (message.includes("Cancel")) {
            return <CancelIcon htmlColor={iconColor} />
        } else if (message.includes("Breakdown")) {
            return <img src={coneIcon} alt={`Vehicle Breakdown`} height="18rem" width="18rem" />
        } else if (message.includes("Authority")) {
            return <img src={warningLight} alt={`LTA`} height="18rem" width="18rem" />
        } else if (message.includes("Unload") || message.includes("Discharge")) {
            return <img src={wheelBarrow} alt={`Unload Delivery`} height="18rem" width="18rem" />
        } else if (message.includes("Admix") || message.includes("Balance")) {
            return <img src={admix} alt={`Admix Delivery`} height="18rem" width="18rem" />
        } else if (message.includes("Site")) {
            return <img src={site} alt={`Site Issue`} height="18rem" width="18rem" />
        } else if (message.includes("Delay") || message.includes("Interval")) {
            return <img src={delay} alt={`Delay Delivery`} height="18rem" width="18rem" />
        } else if (message.includes("QC")) {
            return <AccessibilityIcon htmlColor={iconColor} />
        } else if (message.includes("Order Recommendation")) {
            iconColor = theme.palette.primary.main;
            return <ReceiptIcon htmlColor={iconColor} />
        } else if (message.includes("Vehicle Assignment")) {
            iconColor = theme.palette.primary.main;
            return <DirectionsCarIcon htmlColor={iconColor} />
        }
    }

    const closeRankModal = () => {
        setRateModal(false);
    }

    const openRankModal = (data) => {
        if (data?.FeedbackType?.includes("Order Recommendation") || data?.FeedbackType?.includes("Vehicle Assignment")) {
            setRateData(data);
            setRateModal(true);
        }
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem key={'header'}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                >
                    <Typography
                        variant="h5"
                        color="text.primary"
                    >
                        Notifications
                    </Typography>
                    <IconButton
                        aria-label="refresh"
                        size="large"
                        onClick={() => {
                            retry();
                        }}
                        disabled={loading}
                    >
                        <RefreshIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
            </MenuItem>
            {
                notiData && notiData.length > 0 && notiData.map(noti => {
                    return (
                        <MenuItem key={noti.id} sx={{whiteSpace: 'normal', width:'350px'}} divider onClick={() => openRankModal(parseMessage(noti.message))}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: 'whitesmoke' }}>
                                    {avatarImg(parseMessage(noti.message)?.FeedbackType)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={noti.message.includes("Order Recommendation")
                                    ? "Order " + parseMessage(noti.message)?.OrderCode
                                    : "Vehicle " + parseMessage(noti.message)?.Vehicle
                                }
                                secondary={
                                    <Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {parseMessage(noti.message)?.Message || parseMessage(noti.message)?.FeedbackType}
                                        </Typography>
                                        <br/>{`${parseDate(parseMessage(noti.message)?.Started)}`}
                                    </Fragment>
                                }
                            />
                        </MenuItem>
                    )
                })
            }
            {!loading && notiData.length !== 0 && !failed && hasNextPage &&
                <MenuItem key={'see-more'} onClick={() => fetcMorehNoti()} sx={{ justifyContent: 'center' }}>
                    <Typography variant="button" >
                        See more
                    </Typography>
                </MenuItem>
            }
            {!loading && notiData.length === 0 &&
                <Typography variant="body1" fontSize="1.3rem" textAlign={'center'} >
                    No data
                </Typography>
            }
            {!loading && failed &&
                <Stack spacing={2} justifyContent="center" alignItems="center">
                    <ErrorIcon color="error" sx={{ fontSize: '5rem' }} />
                    <Typography variant="h4" component="p" marginTop={`${theme.spacing(1)} !important`}>
                        Failed to fetch data
                    </Typography>
                </Stack>
            }
            {loading && <Box height="calc(100% - 71px)" width="100%" display="flex" justifyContent="center" alignItems="center" sx={{
                backgroundColor: '#80808038'
            }}>
                <CircularProgress />
            </Box>}
            {rateModal &&
                <RatingModal data={rateData} isOpen={rateModal} closeModal={closeRankModal} sendRating={sendRating} />
            }
        </Menu >
    );
};

export default Notifications;