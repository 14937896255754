import { createSlice } from "@reduxjs/toolkit";
import StateSelector from "../selector";
import { ATSSStorage, ATSSStorageKeys } from '../../utils/atss_storage';
import moment from "moment";

const initialState = {
    permits: [],
    selectedPermit: null,
    loading: false,
    failed: false,
    silentRefresh: false,
    lastRefresh: moment().toISOString(),
    permitTypes: [],
    permitPagination : { page: 1, pageSize: 10 },
    vehicle : [],
    exportLoading : false,
    importLoading : false,
    vehicles : [],
    selectedPlant: null,
    plants : [],
    requiredPermits: [],
    requiredPermitPagination : { page: 1, pageSize: 10 },
    requiredPermitLoading: false,
    projectSites : [],
    bannedPermits: [],
    bannedPermitPagination : { page: 1, pageSize: 10 },
    bannedPermitLoading: false,
    preferredPermits: [],
    preferredPermitPagination : { page: 1, pageSize: 10 },
    preferredPermitLoading: false, 
    sortByPermit : {
        'Vehicle Permits': [
          { orderBy: 'Vehicle', order: 'asc' }
        ],
        'Required Permits': [
          { orderBy: 'Project Site Code', order: 'asc' }
        ],
        'Preferred Resources': [
          { orderBy: 'Project Site Code', order: 'asc' }
        ],
        'Banned Resources': [
          { orderBy: 'Project Site Code', order: 'asc' }
        ]
      },
      filters: {
        'Vehicle Permits': {},
        'Required Permits': {},
        'Banned Resources': {},
        'Preferred Resources': {}
      },
      searchTerm: '',
    
};

const storage = new ATSSStorage();

export const permitSlice = createSlice({
    name: 'permit',
    initialState,
    reducers: {
        setPermits: (state, action) => {
            state.permits = action.payload;
        },
        setRequiredPermits: (state, action) => {
            state.requiredPermits = action.payload;
        },
        setBannedPermits: (state, action) => {
            state.bannedPermits = action.payload;
        },
        setPreferredPermits: (state, action) => {
            state.preferredPermits = action.payload;
        },
        setPermitTypes: (state, action) => {
            state.permitTypes = action.payload;
        },
        setSelectedPermit: (state, action) => {
            state.selectedPermit = action.payload;
        },
        setVehicle: (state, action) => {
            state.vehicle = action.payload;
        },
        setVehicles: (state, action) => {
            state.vehicles = action.payload;
        },
        setSelectedPlant: (state, action) => {
            state.selectedPlant = action.payload;
        },
        setPlants: (state, action) => {
            const plants = action.payload.filter(f => f.ZoneTypeEx === 1);
            state.plants = plants;
            if (storage.getSessionItem(ATSSStorageKeys.permitBatchingPlant) !== null && storage.getSessionItem(ATSSStorageKeys.permitBatchingPlant) !== undefined) {
                state.selectedPlant = storage.getSessionItem(ATSSStorageKeys.permitBatchingPlant);
            } else if (plants.length > 0) {
                state.selectedPlant = plants[0];
            }
        },
        setPermitPagination: (state, action) => {
            state.permitPagination = action.payload;
        },
        setRequiredPermitPagination: (state, action) => {
            state.requiredPermitPagination = action.payload;
        },
        setBannedPermitPagination: (state, action) => {
            state.bannedPermitPagination = action.payload;
        },
        setPreferredPermitPagination: (state, action) => {
            state.preferredPermitPagination = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setRequiredPermitLoading: (state, action) => {
            state.requiredPermitLoading= action.payload;
        },
        setBannedPermitLoading: (state, action) => {
            state.bannedPermitLoading= action.payload;
        },
        setPreferredPermitLoading: (state, action) => {
            state.preferredPermitLoading= action.payload;
        },
        setExportLoading: (state, action) => {
            state.exportLoading = action.payload;
        },
        setImportLoading: (state, action) => {
            state.importLoading = action.payload;
        },
        setProjectSites: (state, action) => {
            state.projectSites = action.payload;
        },
        setSortByPermit: (state, action) => {
            const { tabName, order, orderBy } = action.payload;
            const updatedSortByPermit = {
              ...state.sortByPermit,
              [tabName]: [{ orderBy, order }]
            };
            state.sortByPermit = updatedSortByPermit;
          },
        setFailed: (state, action) => {
            state.failed = action.payload;
        },
        setSilentRefresh: (state, action) => {
            state.silentRefresh = action.payload;
            state.lastRefresh = moment().toISOString();
        },
        resetPermitState: (state) => {
            return initialState;
        },
        setFilters: (state, action) => {
            const { tabName, filters } = action.payload;
            state.filters[tabName] = filters;
          },
          
          setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
          },
          
          clearFilters: (state, action) => {
            const tabName = action.payload;
            state.filters[tabName] = {};
            state.searchTerm = '';
          }
    }
});

export const {
    setPermits,
    setSelectedPermit,
    setLoading,
    setFailed,
    setSilentRefresh,
    resetPermitState,
    setPermitTypes,
    setVehicle,
    setPermitPagination,
    setExportLoading,
    setImportLoading,
    setSelectedPlant,
    setVehicles,
    setPlants,
    setRequiredPermits,
    setRequiredPermitPagination,
    setRequiredPermitLoading,
    setBannedPermits,
    setBannedPermitPagination,
    setBannedPermitLoading,
    setPreferredPermits,
    setPreferredPermitPagination,
    setPreferredPermitLoading,
    setProjectSites,
    setSortByPermit,
    setFilters,
    setSearchTerm,
    clearFilters
} = permitSlice.actions;

export const permitSelector = StateSelector('permit');

export default permitSlice.reducer;