
export const ATSSStorageKeys = {
    account: 'account',
    dashboardBatchingPlant: 'dashboardBatchingPlant',
    manageBatchingPlant: 'manageBatchingPlant',
    permitBatchingPlant: 'permitBatchingPlant',
    accountInfo: 'accountInfo',
}

export class ATSSStorage {
    constructor() {
        this.key = 'atssItems';
        this.value = {};
        this.sessionValues = {}

        const storage = localStorage.getItem(this.key);
        this.value = storage ? JSON.parse(storage) : this.value;
        const session = sessionStorage.getItem(this.key);
        this.sessionValues = session ? JSON.parse(session) : this.sessionValues
    }

    //Local Storage functions
    setItem(key, value) {
        this.value = {
            ...this.value,
            [key]: value
        }

        this.saveChanges();
    }

    getItem(key) {
        return this.value[key];
    }

    removeItem(key) {
        delete this.value[key];
        this.saveChanges();
    }

    saveChanges() {
        localStorage.setItem(this.key, JSON.stringify(this.value));
    }

    //Session Storage functions
    setSessionItem(key, value) {
        this.sessionValues = {
            ...this.sessionValues,
            [key]: value
        }

        this.saveSessionChanges();
    }

    getSessionItem(key) {
        return this.sessionValues[key];
    }

    removeSessionItem(key) {
        delete this.sessionValues[key];
        this.saveSessionChanges();
    }

    saveSessionChanges() {
        sessionStorage.setItem(this.key, JSON.stringify(this.sessionValues));
    }
}