import { OrderService } from "./controllers/order_service";
import { VehicleService } from "./controllers/vehicle";
import { VehicleRosterService } from "./controllers/vehicle_roster";
import { VehicleRosterGroupService } from "./controllers/vehicle_roster_group";
import { NotificationService } from "./controllers/notification_service";
import { PermitService } from "./controllers/permit_service";

export class ATSSApi {
    static getVehicleRosterService() {
        return VehicleRosterService.getInstance();
    }
    static getVehicleRosterGroupService() {
        return VehicleRosterGroupService.getInstance();
    }
    static getVehicleService() {
        return VehicleService.getInstance();
    }
    static getOrderService() {
        return OrderService.getInstance();
    }
    static getNotificationService() {
        return NotificationService.getInstance();
    }
    static getPermitService() {
        return PermitService.getInstance();
    }

}