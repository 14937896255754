import { createSlice } from "@reduxjs/toolkit";
import StateSelector from "../selector";
import { ATSSStorage, ATSSStorageKeys } from '../../utils/atss_storage';

const initialState = {
    origins: [],
    orders: [],
    jobs: [],
    vehiclePriority: [],
    orderPriority: [],
    batchingPlant: null,
    silentRefresh: 0,
    loading: true,
    inActive: 0,
    vehicleAvailability: [],
    selectedOrder: [],
    keywordSearch: "",
    requiredPermits: [],
}

const storage = new ATSSStorage();

const parseDate = (date) => {
    let tempDate = new Date(date);
    tempDate.setHours(tempDate.getHours() + 8);
    const month = tempDate.toLocaleString('default', { month: 'numeric' });
    const day = tempDate.toLocaleString('default', { day: '2-digit' });
    const year = tempDate.toLocaleString('default', { year: 'numeric' })
    const timeVal = tempDate.toLocaleString('default', { timeStyle: 'medium', hour12: false });

    return `${year}-${month}-${day} ${timeVal}`
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        setDashboardOrigins: (state, action) => {
            const origins = action.payload.filter(f => f.ZoneTypeEx === 1);
            state.origins = origins;
            if (storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant) !== null && storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant) !== undefined) {
                state.batchingPlant = storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant);
            } else if (origins.length > 0) {
                state.batchingPlant = origins[0];
            }
        },
        setDashboardOrders: (state, action) => {
            state.orders = action.payload;
        },
        setDashboardJobs: (state, action) => {
            state.jobs = action.payload;
        },
        setDashboardBatchingPlant: (state, action) => {
            state.batchingPlant = action.payload;
            state.selectedOrder = [];
        },
        setDashboardLoading: (state, action) => {
            state.loading = action.payload;
        },
        setDashboardVehiclePriority: (state, action) => {
            let vehPrio = action.payload;
            vehPrio.forEach(data => {

                if(data.ready_timestamp){
                    data.ready_timestamp = parseDate(data.ready_timestamp);
                }
                if(data.available_since){
                    data.available_since = parseDate(data.available_since);
                }                

                if (Array.isArray(data.cancelled)) {
                    data.cancelled = data.cancelled;
                } else {
                    data.cancelled = JSON.parse(data.cancelled)
                }

                data.important = false;
                data.feedback_qty = 0;
                data.status = "On-break";

                if (data?.cancelled !== null) {
                    let cancelVehicle = null
                    if (Array.isArray(data.cancelled)) {
                        cancelVehicle = data.cancelled;
                    } else {
                        cancelVehicle = JSON.parse(data.cancelled)
                    }

                    if (cancelVehicle.length > 0) {
                        data.important = true;
                        data.feedback_qty = cancelVehicle[0];
                    }
                }

                for (let factor of data.raw_score) {
                    if (factor.factor_id === 17) {
                        if (factor.score > 0) {
                            data.status = "Ready";
                        }
                    }
                    if (factor.factor_id === 6) {
                        if (factor.score > 0) {
                            data.important = true;
                        }
                        break;
                    }
                };

                for (let message of data?.message_data ?? []) {
                    if (message.feedback_type === "Vehicle Breakdown") {
                        if (message.message.JobID !== 0 && message.message.JobID !== null) {
                            data.important = true;
                        } else {
                            data.important = false
                            data.status = message.message.Message;
                        }
                        data.feedback_qty = message?.message?.Value ?? 0;
                        break;
                    }
                    if (message.feedback_type === "Balance Concrete" || message.feedback_type === "Load Reject") {
                        data.important = true;
                        data.feedback_qty = message?.message?.Value ?? 0;
                        break;
                    }
                };

            });
            state.vehiclePriority = vehPrio;
        },
        setDashboardSilentRefresh: (state, action) => {
            state.silentRefresh = action.payload ?? (state.silentRefresh + 1);
            state.inActive++;
        },
        setDashboardOrderPriority: (state, action) => {
            state.orderPriority = action.payload;
        },
        resetInActive: (state, action) => {
            state.inActive = 0;
        },
        setDashboardVehicleAvailability: (state, action) => {
            state.vehicleAvailability = action.payload;
        },
        resetDashboardState: (state, action) => {
            return initialState;
        },
        setSelectedOrders: (state, action) => {
            state.selectedOrder = action.payload;
        },
        setKeywordSearch: (state, action) => {
            state.keywordSearch = action.payload;
        },
        setRequiredPermits: (state, action) => {
            state.requiredPermits = action.payload;
        }
    }
});

export const {
    setDashboardOrigins,
    setDashboardOrders,
    setDashboardBatchingPlant,
    setDashboardJobs,
    setDashboardLoading,
    setDashboardVehiclePriority,
    setDashboardSilentRefresh,
    setDashboardOrderPriority,
    resetInActive,
    setDashboardVehicleAvailability,
    resetDashboardState,
    setSelectedOrders,
    setKeywordSearch,
    setRequiredPermits,
} = dashboardSlice.actions;

export const dashboardSelector = StateSelector('dashboard');

export default dashboardSlice.reducer